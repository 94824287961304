/*eslint-disable */
import { bus } from '@/main';
import toast from '@/services/toast';
export default {
  name: 'upload-multiple-file-of-health-record',
  components: {},
  props: ['hRDataFile'],
  data () {
    return {
      title:'',
      recordFor:'',
      first_name:'',
      last_name:'',
      files:null,
      file:null,
      getMulitpleFileID:[],
      getMulitpleFileUrl:[],
      uploadAttachmentName:'',
      draftUploadFile:null,
      isSubmitted:false
    }
  },
  computed: {

  },
  mounted () {
    if(this.hRDataFile != undefined){
      this.title = this.hRDataFile.title
      this.first_name = this.hRDataFile.first_name
      this.last_name = this.hRDataFile.last_name
      this.recordFor = this.hRDataFile.record_for || ''
      this.file = this.hRDataFile.file
      this.draftUploadFile = this.hRDataFile.file
    }

  },
  methods: {
    checkRecordForTxt(){
      if(this.recordFor == 'self'){
        this.$store.dispatch('getSelfUserInfoApiFun').then((res) => {
          this.first_name = res.data.data.first_name
          this.last_name = res.data.data.last_name
        })
      }else{
        this.first_name = ''
        this.last_name = ''
        this.$validator.reset()
      }
    },
    handleAttachmentUpload(event) {
      this.isSubmitted = true
      this.file = event.target.files[0];
      this.files = this.$refs.file.files[0];
      this.uploadAttachmentName = this.files.name
      var formData = new FormData();
      formData.append('file', this.files)
      formData.append('fileName', this.uploadAttachmentName)
      this.$store.dispatch('uploadMulitpleFileOfHrFunc', formData)
        .then((res) => {  
          this.isSubmitted = false
          let record = { 
            file : res.data.data.file,
            fileName : this.uploadAttachmentName.split('.')[1]
          }
          this.getMulitpleFileID.push(res.data.data.id)
          this.getMulitpleFileUrl.push(record)
      }).catch((err) => {
        this.isSubmitted = false
      })
    },
    clearUploadedFileForModelTrainerMethod(){
      this.file = null
      this.uploadAttachmentName = ''
      document.getElementById("uploadCaptureInputFile").value = "";
      this.$validator.reset()
    },
    removeUploadedImgMethod(_index){
      this.getMulitpleFileID.splice(_index, 1)
      this.getMulitpleFileUrl.splice(_index, 1)
      if(this.getMulitpleFileID.length == 0){
        this.clearUploadedFileForModelTrainerMethod();
      }
    },
    UploadMedicalFormBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitted =  true
          var formData = { 
            title:this.title,
            record_for:this.recordFor,
            first_name:this.first_name,
            last_name:this.last_name,
            file_id:this.getMulitpleFileID
          }
          this.$store.dispatch('updatedMulitpleFileOfHrFunc', formData)
            .then((res) => {  
              toast.success(res.data.msg);
              this.submitted =  false
              this.getUpdateHealthRecordsListFun()
              this.closeUploadMedicalModal()
            }).catch((err) => {
              this.submitted =  false
              toast.error(err.data.user_msg || err.data.msg);
            })
        }
      })
    },
    closeUploadMedicalModal() {
      bus.$emit('uploadMedicalRecordsModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    getUpdateHealthRecordsListFun() {
      this.$store.dispatch('getHelthRecordsListApiFun')
    },
  }
}