/*eslint-disable */
import { bus } from '@/main';
import toast from '@/services/toast';
export default {
  name: 'upload-medical-records-form',
  components: {},
  props: ['hRDataForm'],
  data() {
    return {
      disease: '',
      subcategory: '',
      rtype: '',
      file: null,
      name: '',
      category: '',
      uploadAttachmentName: '',
      submitted: false,
      diseaseListData: [],
      categorySelectorState: false,
      subDiseaseListData: [],
      first_name: '',
      last_name: '',
      gender: '',
      dob: '',
      height: '',
      weight: '',
      any_allergies: '',
      drug_allergies: '',
      ever_had: [],
      other_Illness: '',
      operations_date: '',
      current_medicine: '',
      exercise: '',
      eating_diet: '',
      alcohol_consumption: '',
      caffeine_consumption: '',
      smoke: '',
      medical_history: '',
      firstStepFormOfHR: true,
      secondStepFormOfHR: false,
      thirdStepFormOfHR: false,
      allergiesList: [
        'Anemia',
        'Asthma',
        'Arthritis',
        'Cancer',
        'Gout',
        'Diabetes',
        'Emotional Disorder',
        'Epilepsy Seizures',
        'Fainting Spells',
        'Gallstones',
        'Heart Disease',
        'Heart Attack',
        'Rheumatic Fever',
        'High Blood Pressure',
        'Digestive Problems',
        'Ulcerative Colitis',
        'Ulcer Disease',
        'Hepatitis',
        'Kidney Disease',
        'Liver Disease',
        'Sleep Apnea Use',
        'a C-PAP machine',
        'Thyroid Problems',
        'Tuberculosis',
        'Venereal Disease',
        'Neurological Disorders',
        'Bleeding Disorders',
        'Lung Disease',
        'Emphysema'
      ],
      hrTitle: 'Health Records',
      recordFor:'',
      blood_type:'',
      title:''
    }
  },
  computed: {

  },
  mounted() {
    this.$store.dispatch('getDiseasesListDataApiFun').then((res) => {
      this.diseaseListData = res.data.data.results
    }).catch((err) => {
    })
    if(this.hRDataForm != undefined){
      this.title = this.hRDataForm.title
      this.first_name = this.hRDataForm.first_name
      this.last_name = this.hRDataForm.last_name
      this.gender = this.hRDataForm.gender
      this.dob = this.hRDataForm.dob
      this.weight = this.hRDataForm.weight
      this.height = this.hRDataForm.height
      this.any_allergies = this.hRDataForm.any_allergies
      this.drug_allergies = this.hRDataForm.drug_allergies
      this.ever_had = this.hRDataForm.ever_had
      this.other_Illness = this.hRDataForm.other_Illness
      this.operations_date = this.hRDataForm.operations_date
      this.current_medicine = this.hRDataForm.current_medicine
      this.exercise = this.hRDataForm.exercise
      this.eating_diet = this.hRDataForm.eating_diet
      this.alcohol_consumption = this.hRDataForm.alcohol_consumption
      this.caffeine_consumption = this.hRDataForm.caffeine_consumption
      this.smoke = this.hRDataForm.smoke
      this.medical_history = this.hRDataForm.medical_history 
      this.recordFor = this.hRDataForm.record_for || ''
      this.blood_type = this.hRDataForm.blood_type || ''
    }
  },
  methods: {
    checkRecordForTxt(){
      if(this.recordFor == 'self'){
        this.$store.dispatch('getSelfUserInfoApiFun').then((res) => {
          this.first_name = res.data.data.first_name
          this.last_name = res.data.data.last_name
          this.gender = res.data.data.gender
          this.dob = res.data.data.dob
          this.weight = res.data.data.profile.weight
          this.height = res.data.data.profile.height
          this.blood_type = res.data.data.profile.blood_type
        })
      }else{
        this.first_name = ''
        this.last_name = ''
        this.gender = ''
        this.dob = ''
        this.weight = ''
        this.height = ''
        this.blood_type = ''
        this.$validator.reset()
      }
    },
    subDiseasesCheckFun() {
      if (this.disease.sub_disease.length > 0) {
        this.subDiseaseListData = this.disease.sub_disease
      } else {
        this.subDiseaseListData = []
      }
    },
    checkCategoryValFun(val) {
      if (val == 'disease specific') {
        this.categorySelectorState = true
      } else {
        this.categorySelectorState = false
      }
    },
    handleAttachmentUpload(event) {
      if (event.target.files && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.files = this.$refs.file.files[0];
        this.uploadAttachmentName = this.files.name
      } else {
        this.uploadAttachmentName = '';
      }
    },
    UploadMedicalFormBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // this.submitted =  true
          // var formData = new FormData();
          // formData.append('full_name', this.name);
          // formData.append('type', this.rtype);
          // formData.append('category', this.category);
          // formData.append('data_file', this.file);
          // if(this.disease != ''){
          //   formData.append('disease', this.disease.name);
          // }
          // if(this.subcategory != ''){
          //   formData.append('sub_category', this.subcategory);
          // }
          // this.$store.dispatch('uploadHelthRecordsDataApiFun', formData)
          //   .then((res) => {  
          //     toast.success(res.data.msg);
          //     this.submitted =  false
          //     this.getUpdateHealthRecordsListFun()
          //     this.closeUploadMedicalModal()
          //   }).catch((err) => {
          //     this.submitted =  false
          //     toast.error(err.data.user_msg || err.data.msg);
          //   })
          // return;
        }
      })
    },
    closeUploadMedicalModal() {
      bus.$emit('uploadMedicalRecordsModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    getUpdateHealthRecordsListFun() {
      this.$store.dispatch('getHelthRecordsListApiFun')
    },
    firstFormOfHealthRecordMethod() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.firstStepFormOfHR = false
          this.secondStepFormOfHR = true
          this.hrTitle = 'My Medical History'
        }
      })
    },
    backFirstFormOfHealthRecordMethod() {
      this.firstStepFormOfHR = true
      this.secondStepFormOfHR = false
      this.hrTitle = 'Health Records'
      window.scrollTo(0, 0)
    },
    secondFormOfHealthRecordMethod() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.secondStepFormOfHR = false
          this.thirdStepFormOfHR = true
          this.hrTitle = 'My Health Habits'
          window.scrollTo(0, 0)
        }
      })
    },
    backSecondFormOfHealthRecordMethod() {
      setTimeout(() => {
        this.thirdStepFormOfHR = false
        this.secondStepFormOfHR = true
        this.hrTitle = 'My Medical History'
        window.scrollTo(0, 0)
      }, 100);
    },
    thirdFormOfHealthRecordMethod() {
      this.submitted =  true
      var formData = {
        first_name:this.first_name,
        last_name:this.last_name,
        gender:this.gender,
        dob:this.dob,
        height:this.height,
        weight:this.weight,
        any_allergies:this.any_allergies,
        drug_allergies:this.drug_allergies,
        ever_had:JSON.stringify(this.ever_had),
        other_Illness:this.other_Illness,
        operations_date:this.operations_date,
        current_medicine:this.current_medicine,
        exercise:this.exercise,
        eating_diet:this.eating_diet,
        alcohol_consumption:this.alcohol_consumption,
        caffeine_consumption:this.caffeine_consumption,
        smoke:this.smoke,
        medical_history:this.medical_history,
        record_for:this.recordFor,
        blood_type:this.blood_type,
        title:this.title
      }
      if(this.hRDataForm != undefined){
        this.$store.dispatch('updateHealthRecordDataByIdApiFun', {data:{data:formData,id:this.hRDataForm.id}})
          .then((res) => {  
            let notificationPayload = { 
              type:'health_record_uploaded',
              data_id:res.data.data.id,
              message:"Health Record has been uploaded"
            }
            this.$store.dispatch('postNotificationFunc', notificationPayload)    
            toast.success(res.data.msg);
            this.submitted =  false
            this.getUpdateHealthRecordsListFun()
            this.closeUploadMedicalModal()
          }).catch((err) => {
            this.submitted =  false
            toast.error(err.data.user_msg || err.data.msg);
          })
      }else{
        this.$store.dispatch('uploadHelthRecordsDataApiFun', formData)
          .then((res) => {  
            let notificationPayload = { 
              type:'health_record_uploaded',
              data_id:res.data.data.id,
              message:"Health Record has been uploaded"
            }
            this.$store.dispatch('postNotificationFunc', notificationPayload)   
            toast.success(res.data.msg);
            this.submitted =  false
            this.getUpdateHealthRecordsListFun()
            this.closeUploadMedicalModal()
          }).catch((err) => {
            this.submitted =  false
            toast.error(err.data.user_msg || err.data.msg);
          })
        }
      return;
    }
  }
}